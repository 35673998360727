
 
import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import { injectIntl } from "gatsby-plugin-intl"

const TermsandConditionsPage = ({ intl }) => {

const isEnglish = () => {
return (intl.locale === "en")
}

return (

<Layout> 

<SEO
    site={siteMetadata}
    title="Terms & Conditions"
    description="CIGAM pledges to coach students using best practices while adhering to ethical principles in their journey to studying and living abroad."
   /> 
  
<section id="termsandconditions">
<div className="container">
      
    <div className="text-center mb-5 mt-5">
        <div>
            <h1>Terms and Conditions</h1>
            <h2>Terms of use</h2>
            <p>CIGAM and its affiliates provide its content on its websites or applications that post a link to this Terms of Use subject to the following terms and conditions. We may periodically change the Terms without prior notice, so please check back from time to time. These Terms were last updated on November 2020. By accessing and using this Site, you agree to these Terms. For an explanation of CIGAM’s practices and policies related to the collection, use, and storage of our users’ information, please read our Privacy Policy.</p>
        </div>
        <div>
            <h2>Copyrights</h2>
            <p>All content and functionality on the Site, including text, graphics, logos, icons, images, and videos and the selection and arrangement thereof, in addition to any concepts, know-how, tools, frameworks, software, applications or other technology, algorithms, models, processes, and industry perspectives underlying or embedded in the foregoing, along with any enhancements to or derivative works thereof  is the exclusive property of CIGAM or its licensors and, to the extent applicable, is protected by T.H. and copyright law of Thailand. Neither the Site Content nor functionality of the Site, may be copied, reproduced, modified, reverse engineered, altered (including the removal or disabling of any security or technological safeguards, disclaimers, or legends) uploaded, published, uploaded, posted, transmitted, or distributed in any way without our written permission. All rights not expressly granted are reserved.</p>
        </div>
        <div>
            <h2>Trademarks</h2>
            <p>The trademarks, service marks, designs, and logos displayed on the Site are the registered and unregistered Trademarks of CIGAM and its licensors. You agree that, except as expressly permitted by us (e.g., through social media sharing tools provided on the Site) or by our licensors, where applicable, you will not refer to or attribute any information to CIGAM or its licensors in any public medium (e.g., press release, websites, or public social media) for advertising or promotion purposes, or for the purpose of informing or influencing any third party and that you will not use or reproduce any Trademark of, or imply any endorsement by or relationship with, CIGAM or its licensors</p>
        </div>
        <div>
            <h2>Use of site content</h2>
            <p>CIGAM hereby grants you a limited, non-exclusive, non-transferable, revocable license for the term hereof to access and download, display, and print one copy of the Site Content on any single computer solely for your internal, business use, provided that you do not modify the Site Content in any way (including creating derivative works thereof), that you retain all copyright and other proprietary notices displayed on the Site Content, and that you otherwise comply with these Terms. You may not otherwise reproduce, modify, reverse engineer, distribute, transmit, post, or disclose the Site Content without CIGAM’s prior written consent. In addition, you may not “mirror” the Site Content or any portion thereof without CIGAM’s express written consent. Nothing on this Site should be construed as granting directly or indirectly, or by implication any license or right to use any CIGAM intellectual property other than as expressly set forth herein. The license granted in this section terminates automatically and immediately if you do not comply with these Terms.</p>
        </div>
        <div>
            <h2>User postings</h2>
            <p>You acknowledge and agree that CIGAM shall own and have the unrestricted right to use, publish, and otherwise exploit any and all information that you post or otherwise publish on the Site in postings, forums or message boards, questionnaire, survey responses, and otherwise, and you acknowledge and agree that, by providing us any such submission, you automatically grant, and hereby do grant, to us a worldwide, non-exclusive, transferable, assignable, sublicensable, fully paid-up, royalty-free, perpetual, irrevocable license and right to use, reproduce, publish, distribute, modify and otherwise exploit such submission for any purpose, and in any form or media, not prohibited by applicable law. In addition, you hereby waive any claims against CIGAM for any alleged or actual infringements of any rights of privacy or publicity, intellectual property rights, moral rights, or rights of attribution in connection with CIGAM’s use and publication of such submissions.</p>
            <p>You covenant that you shall not post or otherwise publish on the Site any materials that (a) are threatening, libellous, defamatory, or obscene; (b) would constitute, or that encourage conduct that would constitute, a criminal offence, give rise to civil liability, or otherwise violate the law; (c) infringe the intellectual property, privacy, or other rights of any third parties; (d) contain a computer virus or other destructive element; (e) contain advertising; (f) constitute or contain false or misleading statements; or (g) violates these Terms.</p>
            <p>Site by users. In addition, CIGAM does not and cannot review all information posted to the Site by users and is not responsible for such information. However, CIGAM reserves the right to refuse to post and the right to remove any information, in whole or in part, for any reason or for no reason.</p>
        </div>
        <div>
            <h2>Notices of infringement and takedown </h2>
            <p>CIGAM prohibits the posting of any information that infringes or violates the copyright rights and/or other intellectual property rights (including rights of privacy and publicity) of any person or entity. If you believe that your intellectual property right (or such a right that you are responsible for enforcing) is infringed by any content on the Site, please write to CIGAM at the address shown below, giving a written statement that contains: (a) identification of the copyrighted work and/or intellectual property right claimed to have been infringed; (b) identification of the allegedly infringing material on the Site that is requested to be removed; (c) your name, address, and daytime telephone number, and an e-mail address if available; (d) a statement that you have a good faith belief that the use of the copyrighted work and/or exercise of the intellectual property right is not authorized by the owner, its agent, or the law; (e) a statement that the information in the notification is accurate, and, under penalty of perjury, that the signatory is authorized to act on behalf of the owner of the right that is allegedly infringed; and (f) the signature of the intellectual property right owner or someone authorized on the owner’s behalf to assert infringement of the right. CIGAM will remove any posted submission that infringes the copyright or other intellectual property right of any person under U.S. law upon receipt of such a statement (or any statement in conformance with 17 U.S.C. 512(c)(3)). U.S. law provides significant penalties for submitting such a statement falsely. Under appropriate circumstances, persons who repeatedly submit infringing or unlawful material will be prohibited from posting further submissions. </p>
        </div>
        <div>
            <h2>Disclaimers</h2>
            <p>THESE SITES AND ANY INFORMATION, PRODUCTS OR SERVICES THEREIN ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR USE OF A PARTICULAR PURPOSE, OR NON INFRINGEMENT.</p>
            <p>CIGAM does not warrant, and hereby disclaims any warranties, either express or implied, with respect to the accuracy, adequacy or completeness of any Site, information obtained from a Site, or link to a Site. Stanford does not warrant that Sites will operate in an uninterrupted or error-free manner or that Sites are free of viruses or other harmful components. Use of information obtained from or through these Sites is at your own risk.</p>
            <p>YOU AGREE THAT CIGAM WILL NOT BE LIABLE TO YOU FOR ANY LOSS OR DAMAGES, EITHER ACTUAL OR CONSEQUENTIAL, ARISING OUT OF OR RELATING TO THESE TERMS, OR TO YOUR (OR ANY THIRD PARTY’S) USE OR INABILITY TO USE A SITE, OR TO YOUR PLACEMENT OF CONTENT ON A SITE, OR TO YOUR RELIANCE UPON INFORMATION OBTAINED FROM OR THROUGH A SITE. IN PARTICULAR, STANFORD WILL HAVE NO LIABILITY FOR ANY CONSEQUENTIAL, INDIRECT, PUNITIVE, SPECIAL OR INCIDENTAL DAMAGES, WHETHER FORESEEABLE OR UNFORESEEABLE, (INCLUDING, BUT NOT LIMITED TO, CLAIMS FOR DEFAMATION, ERRORS, LOSS OF DATA, OR INTERRUPTION IN AVAILABILITY OF DATA), ARISING OUT OF OR RELATING TO THESE TERMS, YOUR USE OR INABILITY TO USE A SITE, OR ANY PURCHASES ON THIS SITE, OR YOUR PLACEMENT OF CONTENT ON A SITE, OR TO YOUR RELIANCE UPON INFORMATION OBTAINED FROM OR THROUGH A SITE, WHETHER BASED IN CONTRACT, TORT, STATUTORY OR OTHER LAW, EXCEPT ONLY IN THE CASE OF DEATH OR PERSONAL INJURY WHERE AND ONLY TO THE EXTENT THAT APPLICABLE LAW REQUIRES SUCH LIABILITY.</p>
            <p>CIGAM makes no representation regarding your ability to transmit and receive information from or through the Sites and you agree and acknowledge that your ability to access the Sites may be impaired. CIGAM disclaims any and all liability resulting from or related to such events.</p>
        </div>
        <div>
            <h2>Indemnification</h2>
            <p>You hereby indemnify, defend, and hold harmless CIGAM and all of its predecessors, successors, parents, subsidiaries, affiliates, officers, directors, shareholders, investors, employees, agents, representatives, and attorneys and their respective heirs, successors, and assigns (“CIGAM Indemnified Parties”) from and against any and all liability, expenses, costs, or other losses (“Losses”) incurred by CIGAM and/or CIGAM Indemnified Parties in connection to any claims arising out of your use of the Site and/or any breach by you of these Terms, including the representations, warranties and covenants you made, if any, by agreeing to these Term. CIGAM reserves the right to assume, at its own expense, the exclusive defense and control of any matter otherwise subject to indemnification by you.</p>
        </div>
        <div>
            <h2>Third-party websites & Providers</h2>
            <p>We may provide links to third-party websites, and some of the content appearing to be on this Site is in fact supplied, supported, or provided directly or indirectly by third parties, for example, in instances of framing of third-party websites or incorporation through framesets of content supplied by third-party servers. CIGAM has no responsibility for these third-party websites, which are governed by the terms of use and privacy policies, if any, of the applicable third-party content providers.</p>
        </div>
        <div>
            <h2>Governing law; jurisdiction</h2>
            <p>These Terms are exercised by the law of Thailand without arguement to the principles of conflicts of laws thereof.</p>
        </div>
    </div>
</div>
</section>
</Layout>
)
}

export default injectIntl(TermsandConditionsPage)


